.txt-links-section-wrapper {
  background-color: var(--section-card-bg) !important;
  margin-bottom: 1rem !important;
  border-radius: var(--section-card-border-radius);
  box-shadow: var(--section-card-box-shadow);
  max-width: var(--screen-lg);
  margin-inline: auto;
}

.txt-links {
  height: 6ch;
  overflow: hidden;
  transition: height 0.5s ease;
}

.txt-links.show-more {
  height: auto;
}

.show-more-toggle-btn {
  color: gray;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-block: 0.7rem 0.5rem;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
}
